import React from "react";
import {
  Navbar, 
  NavbarBrand, 
  NavbarContent, 
  NavbarItem, 
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem
} from "@nextui-org/navbar";
import {Button, DropdownItem, DropdownTrigger, Dropdown, DropdownMenu} from "@nextui-org/react";
import {ChevronDown, Ceramic, House, Tint, Mobile, Detail, Shield, Business, Plus} from "./Icons.jsx";
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import { Container, createStyles } from "@mantine/core";

const useStyles = createStyles((theme) => ({
  header: {
    // display: 'flex',
    // justifyContent: 'space-between',
    // alignItems: 'center',
    // height: '100%',
  },
}));

export default function NavBar() {
  const icons = {
    chevron: <ChevronDown fill="currentColor" size={16} />,
    business: <Business className="text-warning" fill="currentColor" size={30} />,
    house: <House className="text-success" fill="currentColor" size={30} />,
    tint: <Tint className="text-secondary" fill="currentColor" size={30} />,
    mobile: <Mobile className="text-primary" fill="currentColor" size={30} />,
    detail: <Detail className="text-success" fill="currentColor" size={30} />,
    ceramic: <Ceramic className="text-danger" fill="currentColor" size={30} />,
    shield: <Shield className="text-danger" fill="currentColor" size={30} />,
    plus: <Plus className="text-danger" fill="currentColor" size={30} />,
  };
  
  const { classes } = useStyles();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  const menuItems = [
    "Profile",
    "Dashboard",
    "Activity",
    "Analytics",
    "System",
    "Deployments",
    "My Settings",
    "Team Settings",
    "Help & Feedback",
    "Log Out",
  ];

  return (
    
    <Navbar height='85px' maxWidth="xl" isBordered isMenuOpen={isMenuOpen} onMenuOpenChange={setIsMenuOpen}>

        <NavbarMenuToggle
          aria-label={isMenuOpen ? "Close menu" : "Open menu"}
          className="sm:hidden"
        />

      <NavbarBrand>
        <NavLink to='/'>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img className="logo" src="images/logo.png" alt='logo' />
      </Link>
        </NavLink>
      </NavbarBrand>
      
      <NavbarMenu>

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/ceramic-coatings"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Ceramic Coating
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/paint-protection-film"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Paint Protection Film
            </Link>
          </NavbarMenuItem>


          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/vinyl-wrap"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Vinyl Wrap
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/paint-correction"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Paint Correction
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/detailing"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Detailing
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/headlight-restoration"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Headlight Restoration
            </Link>
          </NavbarMenuItem>

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/additional-services"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Additional Services
            </Link>
          </NavbarMenuItem>

          {/* <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/reviews"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Reviews
            </Link>
          </NavbarMenuItem> */}

          <NavbarMenuItem key='1'>
            <Link
              color="primary"
              className="w-full"
              to="/product-line"
              onClick={()=> isMenuOpen(false)}
              size="lg"
            >
              Product Line
            </Link>
          </NavbarMenuItem>
   
      </NavbarMenu>

      <NavbarContent className="hidden sm:flex gap-4" justify="center">
        <Dropdown 
        showArrow
        classNames={{
          base: "before:bg-default-200", // change arrow background
          content: "py-1 px-1 border border-default-200 bg-gradient-to-br from-gray to-default-200 dark:from-default-50 dark:to-black",
        }}
        backdrop="blur"
        >
          <NavbarItem >
            <DropdownTrigger>
              <div style={{ cursor: 'pointer', textTransform: 'uppercase', fontFamily: 'SceneProBold', letterSpacing: '2px', fontSize: '13px' }} endContent={icons.chevron}>Services</div>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu
          variant="faded"
            aria-label="Supreme Detail Studio Services"
            className="w-[240px] h-[100%]"
            itemClasses={{
              base: "gap-2 ",

            }}
          >
            <DropdownItem
              key="autoscaling"
              // startContent={icons.ceramic}
              // description="Provides durable and long-lasting protection to surfaces from light scratches, UV rays, chemicals, and environmental contaminants."
            >
             
             <Link to='/ceramic-coatings' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Ceramic Coating
              </Link>

            </DropdownItem>
            <DropdownItem
              key="usage_metrics"
              // startContent={icons.shield}
              // description="Protect and preserve the paint of your vehicle against rock chips, road debris and minor scratches with our professionally installed Paint Protection Film."
            >
              <Link to='/paint-protection-film' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Paint Protection Film
              </Link>
            </DropdownItem>

            <DropdownItem
              key="usage_metrics"
              // startContent={icons.shield}
              // description="Protect and preserve the paint of your vehicle against rock chips, road debris and minor scratches with our professionally installed Paint Protection Film."
            >
              <Link to='/vinyl-wrap' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Vinyl Wrap
              </Link>
            </DropdownItem>

            <DropdownItem
              key="production_ready"
              // startContent={icons.detail}
              // description="Is your car starting to develop swirls or lacking that original gloss and luster from when you first bought it? Then we have the solution for you."
            >
              <Link to='/paint-correction' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Paint Correction
              </Link>
            </DropdownItem>

            <DropdownItem
              key="99_uptime"
              // startContent={icons.detail}
              // description="Much more than just your ordinary car wash. Come see what the premium side of detailing can do for your vehicle."
            >
              <Link to='/detailing' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Detailing
              </Link>
            </DropdownItem>

            {/* <DropdownItem
              key="supreme_support"
              // startContent={icons.house}
              // description="Protect and enhance your kitchen with our protective film installs."
            >
             <Link to='/residential-services' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Residential Services
              </Link>
            </DropdownItem> */}


<DropdownItem
              key="supreme_support"
              // startContent={icons.plus}
              // description="Customize your detailing experience with the additional services we offer"
            >
              <Link to='/headlight-restoration' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Headlight Restoration
              </Link>
            </DropdownItem>

            <DropdownItem
              key="supreme_support"
              // startContent={icons.plus}
              // description="Customize your detailing experience with the additional services we offer"
            >
              <Link to='/additional-services' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Additional Services
              </Link>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>

        {/* <NavbarItem>
        <NavLink to='/tesla-customers' style={{ textTransform: 'uppercase', fontFamily: 'SceneProBold', letterSpacing: '2px', fontSize: '13px' }}>
            Tesla Customers
          </NavLink>
        </NavbarItem> */}

        {/* <NavbarItem>
        <NavLink to='/reviews' style={{ textTransform: 'uppercase', fontFamily: 'SceneProBold', letterSpacing: '2px', fontSize: '13px' }}>
            Reviews
          </NavLink>
        </NavbarItem> */}

        <NavbarItem>
        <NavLink to='/product-line' style={{ textTransform: 'uppercase', fontFamily: 'SceneProBold', letterSpacing: '2px', fontSize: '13px' }}>
            Product Line
          </NavLink>
        </NavbarItem>

        <Dropdown
                showArrow
                classNames={{
                  base: "before:bg-default-200", // change arrow background
                  content: "py-1 px-1 border border-default-200 bg-gradient-to-br from-gray to-default-200 dark:from-default-50 dark:to-black",
                }}
                backdrop="blur"
        >
          <NavbarItem >
            <DropdownTrigger>
              <div style={{ cursor: 'pointer', textTransform: 'uppercase', fontFamily: 'SceneProBold', letterSpacing: '2px', fontSize: '13px' }} endContent={icons.chevron}>More</div>
            </DropdownTrigger>
          </NavbarItem>
          <DropdownMenu
            variant="faded"
            aria-label="Supreme Detail Studio Services"
            className="w-[240px] h-[100%]"
            itemClasses={{
              base: "gap-2 ",

            }}
          >
            <DropdownItem
              key="usage_metrics"
              // startContent={icons.activity}
            >
              <Link to='/before-and-after' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Before And After
              </Link>
            </DropdownItem>
            <DropdownItem
              key="production_ready"
              // startContent={icons.flash}
            >
              <Link to='/gallery' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              Gallery
              </Link>
            </DropdownItem>
            <DropdownItem
              key="99_uptime"
              // startContent={icons.server}
            >
              <Link to='/faq' style={{ padding: '0px', fontFamily:'SceneProBold', textTransform: 'uppercase', color: '#FFF', letterSpacing: '2px', fontSize: '13px' }}>
              FAQs
              </Link>
            </DropdownItem>
           
          </DropdownMenu>
        </Dropdown>
      </NavbarContent>

      <NavbarContent justify="end" className="sm:hidden">
        <NavbarItem>
          <Link to='https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN' target="_blank">
          <Button radius="none" size="sm" style={{ backgroundColor: 'transparent', fontFamily: 'SceneProRg', letterSpacing: '2px', textTransform: 'uppercase' }}>
            Book Now
          </Button>
          </Link>
        </NavbarItem>
      </NavbarContent>

      <NavbarContent justify="end" className="max-sm:hidden">
        <NavbarItem>
          <Link to='https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN' target="_blank">
          <Button radius="md" size="md" style={{ backgroundColor: 'transparent', border: '1px solid #fff', fontFamily: 'SceneProRg', letterSpacing: '3px', textTransform: 'uppercase' }}>
            Book Appointment
          </Button>
          </Link>
        </NavbarItem>
      </NavbarContent>

    </Navbar>
  );
}
