import React from 'react';
import { Helmet } from 'react-helmet';
import Hero from '../components/Heroes/Hero';
// import About from './About';
import Reviews from '../components/Reviews';
import WhyUs from '../components/BenefitsAndImportance/WhyUs';
import Services from '../components/Services/Services';
import Map from '../components/Map';
import Connecting from '../components/BenefitsAndImportance/Connecting';
import CertifiedInstaller from '../components/BenefitsAndImportance/CertifiedInstaller';
import GalleryMasonry from '../components/GalleryMasonry';

const Landing = () => {
  return (
    <>
    <Hero />
    <Services />
    {/* <CertifiedInstaller /> */}
    {/* <Reviews /> */}
    <WhyUs />
    <GalleryMasonry />
    <Connecting />
    {/* <Map /> */}
    </>
  )
}

export default Landing;