import React from 'react';
import { createStyles } from '@mantine/core';
import {Card, CardHeader, CardBody, CardFooter, Image, Button} from "@nextui-org/react";
import { Link } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
  card:{
    height: '440px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    backgroundSize: 'cover',
    backgroundColor: 'transparent',
    borderStyle: 'none',
    transition: 'transform 150ms ease',
    padding: '0',
    // ':hover': {
    //   transform: 'scale(1.02)',
	  // },
    [theme.fn.smallerThan('sm')]: {
      height: '100%'
    },
  },
  image: {
    position: 'absolute',
    inset: 0,
    backgroundSize: 'cover',
    transition: 'transform 150ms ease',
  },
  title: {
    color: '#FFF',
    fontFamily: 'SceneProUltBlk',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: '18px',
    padding: '5px'
  },
  description: {
    color: '#d3d3d3',
    fontFamily: 'SceneProRg',
    fontSize: '14px',
    // marginBottom: '10px',
    padding: '5px'
    // marginTop: '10px',
  },
  viewService: {
    color: '#FFF',
    fontFamily: 'SceneProRg',
    fontSize: '15px',
    marginTop: '23px',
  },
  overlay: {
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), #000)',
    position: 'absolute',
    top: '0%',
    bottom: '0%',
    left: '0%',
    right: '0%',
  },
  content: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    zIndex: 1,
  },
  panel: {
    width: '100%',
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0, 0, 0, .35)',
    padding: '24px 17px 0',
  }
}));

const ServicesCard = (props) => {
  const { classes, theme } = useStyles();

  return (
    <>
    <Link to={props.linkProp} style={{ textDecoration: 'none' }}>
    <Card isFooterBlurred radius='sm'>
      <Image
        removeWrapper
        alt={props.titleProp}
        className="z-0 w-full h-full object-cover"
        src={props.imageProp}
      />
      <CardFooter className="absolute bg-black/40 bottom-0 z-10 border-t-1 border-default-600 dark:border-default-100">
        <div className="flex flex-grow gap-2 items-center">
          <div className="flex flex-col">
          <h4 className={classes.title}>{props.titleProp}</h4>
            <p className={classes.description}>{props.descriptionProp}</p>
            {/* <Link to={props.linkProp} style={{ textDecoration: 'none' }}>
            <Button radius="none" size="sm" variant="shadow" width="100%" style={{ width: '100%', backgroundColor: 'rgb(232, 2, 0)', fontFamily: 'SceneProRg', letterSpacing: '3px', textTransform: 'uppercase', }}>View Service</Button>
            </Link> */}
          </div>
        </div>
      </CardFooter>
    </Card>
    </Link>


{/* <Link to={props.linkProp} style={{ textDecoration: 'none' }}> */}
{/* <Card
      className={classes.card}
      style={{ borderRadius: '5px', backgroundColor: 'transparent', padding: 0 }}
    >
      <div
        className={classes.image}
        style={{
          backgroundImage:
            `url('${props.imageProp}')`,
        }}
      />
      <div className={classes.overlay} />

      <div className={classes.content}>
        <div className={classes.panel}>
          <Text className={classes.title}>
          {props.titleProp}
          </Text>

          <Group>
            <Text className={classes.description}>
            {props.descriptionProp}
            </Text>
          </Group>

          <Link to={props.linkProp} style={{ textDecoration: 'none' }}>
          <Group>
            <Text className={classes.viewService}>
            View Service
            </Text>
          </Group>
          </Link>

        </div>
      </div>
    </Card> */}
    {/* </Link> */}

    {/* <Link to={props.linkProp} style={{ textDecoration: 'none', }}>
    <div className={classes.servicesPanel}>
        <Image src={props.imageProp} alt={props.titleProp} width={500} height={250} className={classes.cardImage} />
      <div className={classes.servicesTextPanel}>

          <Text style={{ fontFamily: `SceneProRg`, fontSize: '20px', fontWeight: 400, lineHeight: 1.1, letterSpacing: '2px', textTransform: 'uppercase', marginTop: 0, marginBottom: 0,  }}>
            {props.titleProp}
          </Text>
          <div className={classes.servicesInfo}>
          <Text style={{ fontFamily: `SceneProRg`, fontSize: '14px', lineHeight: 1.8, letterSpacing: '2px', marginTop: '10px', }}>
          {props.descriptionProp}
          </Text>
          <div className={classes.viewService}>
            View Service
          </div>
          </div>
        </div>
        
    </div>
    </Link> */}
    </>
  )
}

export default ServicesCard;