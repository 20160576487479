import React from 'react';
import Reviews from '../components/Reviews';
import ReviewsHero from '../components/Heroes/ReviewsHero';

const ReviewsPage = () => {

  return (
    <>
    <ReviewsHero />
    <Reviews />
    </>
  )
}

export default ReviewsPage;