import React from 'react'
import PackageCard from '../PackageCard';
import { SimpleGrid } from '@mantine/core';

const FullDetail = () => {
  const basicFull = [
   'Two bucket hand wash ',
   'Bug and tar removal',
   'Tires, wheels, and wheel wells cleaned and dressed',
   'Streak-free exterior glass cleaning',
  
   'Thorough vacuum of floors, mats, seats, and trunk',
   'Clean door and trunk jambs',
   'Interior air compressor blow-out for dust, interior prepped and cleaned',
   'UV Protectant is applied to interior dash and plastics',
   'Leather is deep cleaned and conditioned',
   'Streak-free interior glass cleaning',
  //  'CarFax record update',
  ];

  const deluxeFull = [
    'Two bucket hand wash ',
    'Tires, wheels, and wheel wells cleaned and dressed',
    'Decontaminate paint to remove minor bugs/tar/grime',
    'Decontaminate remaining impurities with clay-bar',
    'Paint is coated with high-quality wax',
    'Streak-free exterior glass cleaning',
  
    'Thorough vacuum of floors, mats, seats, and trunk',
    'Clean door and trunk jambs',
    'Interior air compressor blow-out for dust, interior prepped and cleaned',
    'UV Protectant is applied to interior dash and plastics',
    'Leather is deep cleaned and conditioned',
    'Streak-free interior glass cleaning',
    // 'CarFax record update',
  ];

  const supremeFull = [
    'Two bucket hand wash ',
    'Tires, wheels, and wheel wells cleaned and dressed',
    'Decontaminate paint to remove minor bugs/tar/grime',
    'Decontaminate remaining impurities with clay-bar',
    'Paint is coated with high-quality sealant',
    'Streak-free exterior glass cleaning',
    'Engine bay detailed and dressed',
    'Undercarriage wash',
  
    'Thorough vacuum of floors, mats, seats, and trunk',
    'Clean door and trunk jambs',
    'Interior air compressor blow-out for dust, interior prepped and cleaned',
    'Steam clean all panels, cracks/crevices, cup holders, and seatbelts, for maximum sanitation',
    'Steam clean headliner for maximum sanitation',
    'UV Protectant is applied to interior dash and plastics',
    'Leather is deep cleaned and conditioned',
    'Streak-free interior glass cleaning',
    // 'CarFax record update',
  ];

  return (
    <div>
      <SimpleGrid
      cols={3}
      spacing="lg"
      breakpoints={[
        { maxWidth: '62rem', cols: 3, spacing: 'md' },
        { maxWidth: '48rem', cols: 1, spacing: 'sm' },
        { maxWidth: '36rem', cols: 1, spacing: 'sm' },
      ]}
      >
                <PackageCard
        packageTitle="Bronze Full Detail"
        packageDuration=""
        included={basicFull}
        price="$200"
        bookingLink="https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN"
        />
        <PackageCard
        packageTitle="Silver Full Detail"
        packageDuration=""
        included={deluxeFull}
        price="$325"
        bookingLink="https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN"
        />
        <PackageCard
        packageTitle="Gold Full Detail"
        packageDuration=""
        included={supremeFull}
        price="$400"
        bookingLink="https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN"
        />
      </SimpleGrid>
    </div>
  )
}

export default FullDetail;