import React from 'react';
import { SimpleGrid, createStyles } from '@mantine/core';
import AddOnCard from './AddOnCard';

const useStyles = createStyles((theme) => ({
}));

const AddOnServices = () => {
  const { classes } = useStyles();

  return (
    <>
        <SimpleGrid
        cols={4}
        spacing="lg"
        breakpoints={[
          { maxWidth: '62rem', cols: 4, spacing: 'md' },
          { maxWidth: '48rem', cols: 2, spacing: 'sm' },
          { maxWidth: '36rem', cols: 2, spacing: 'sm' },
        ]}
        >
          {/* <AddOnCard titleProp='Chrome Polish' priceProp='$25' /> */}
          <AddOnCard titleProp='Engine Bay Cleaning' priceProp='$50' />
          <AddOnCard titleProp='Headliner Cleaning' priceProp='$50' />
          {/* <AddOnCard titleProp='Interior Ceramic Coating' priceProp='$250' /> */}
          {/* <AddOnCard titleProp='Leather Ceramic Coating' priceProp='$175' />
          <AddOnCard titleProp='Leather Clean & Treatment' priceProp='$50' /> */}
          <AddOnCard titleProp='Hot Water Extraction' priceProp='$100' />
          <AddOnCard titleProp='Pet Hair Removal' priceProp='$75' />
          {/* <AddOnCard titleProp='Black Trim Restore & Protect' priceProp='$15' /> */}
          <AddOnCard titleProp='Headlight Restoration' priceProp='$100' />
          <AddOnCard titleProp='Ozone Odor Treatment' priceProp='$75' />
          <AddOnCard titleProp='Biohazards Fee' priceProp='$100' />
        </SimpleGrid>
    </>
  )
}

export default AddOnServices;