import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet';
import HeaderMenu from './components/Header';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Landing from './pages/LandingPage';
import Detailing from './pages/DetailingPage';
import FullDetailing from './pages/FullDetailPage';
import ExteriorDetailing from './pages/ExteriorDetailPage';
import InteriorDetailing from './pages/InteriorDetailPage';
import AddOnServicesPage from './pages/AddOnServicePage';
import HeadlightRestoration from './pages/HeadlightRestorationPage';
import PPF from './pages/PPFPage';
import CeramicCoatings from './pages/CeramicCoatingsPage';
import PaintCorrection from './pages/PaintCorrectionPage';
import WindowTintPage from './pages/WindowTintPage';
import Contact from './pages/ContactPage';
import ReviewsPage from './pages/ReviewsPage';
import GalleryPage from './pages/GalleryPage';
import BeforeAndAfterPage from './pages/BeforeAndAfterPage';
import FaqPage from './pages/FAQPage';
import {NextUIProvider} from "@nextui-org/react";
import NavBar from './components/NavigationBar';

function App() {

  return (
    <NextUIProvider>
      <main className="dark text-foreground bg-background">
    <ParallaxProvider>
    <Router>
    <Helmet>
      <title>Element 78 Detailing | Auto Detailing In Elizabethtown, KY</title>
      <meta name='title' content='Element 78 Detailing | Auto Detailing In Elizabethtown, KY' />
      <meta name='description' content='Elizabethtown, KY' />
      <meta name='keywords' content='Element 78 Detailing, Elizabethtown car detail, mobile detailing, Elizabethtown ceramic coating, Elizabethtown mobile detail, ceramic coating, Elizabethtown paint correction,' />
      <meta property="og:title" content='Element 78 Detailing | Auto Detailing In Elizabethtown, KY' />
      <meta property="og:description" content='Elizabethtown, KY Auto Detailing' />
      <meta property="og:image" content='%PUBLIC_URL%/preview.png' />
    </Helmet>
      <ScrollToTop>
      {/* <ScrollToTop> */}
        {/* <AlertMessage /> */}
        {/* <HeaderMenu /> */}
        <NavBar />
          <Routes>
            <Route path='/detailing' element={<Detailing />} />
            <Route path='/exterior-detail' element={<ExteriorDetailing />} />
            <Route path='/interior-detail' element={<InteriorDetailing />} />
            <Route path='/full-detail' element={<FullDetailing />} />
            <Route path='/additional-services' element={<AddOnServicesPage />} />
            <Route path='/headlight-restoration' element={<HeadlightRestoration />} />
            <Route path='/paint-protection-film' element={<PPF />} />
            <Route path='/ceramic-coatings' element={<CeramicCoatings />} />
            <Route path='/paint-correction' element={<PaintCorrection />} />
            <Route path='/vinyl-wrap' element={<WindowTintPage />} />
            <Route path='/gallery' element={<GalleryPage />} />
            <Route path='/reviews' element={<ReviewsPage />} />
            <Route path='/contact-us' element={<Contact />} />
            <Route path='/faq' element={<FaqPage />} />
            <Route path='/before-and-after' element={<BeforeAndAfterPage />} />
            <Route path='/' element={<Landing />} />
          </Routes>
        <Footer />
        </ScrollToTop>
    </Router>
    </ParallaxProvider>
    </main>
    </NextUIProvider>
  );
}

export default App;