import React from 'react';
import { Container, Title, SimpleGrid, createStyles } from '@mantine/core';
import ServicesCard from './ServicesCard';
import LeatherCoating from '../../assets/images/details/systemx-interior.jpeg';
import Tesla from '../../assets/images/details/tesla-paint-correction.jpg';
import BMWInterior from '../../assets/images/details/bmw-dashboard.jpg';
import BMWSeats from '../../assets/images/details/leather-seats.jpg';
import CeramicCoating from '../../assets/images/ceramic-coatings/armor.webp';
import PPF from '../../assets/images/details/paint-protection-film.jpeg';
import Vinyl from '../../assets/images/details/wrap.webp';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: '15px',
    paddingBottom: '4rem',
    position: 'relative',
  },
  h1: {
    marginTop: 0,
    fontFamily: 'SceneProUltBlkIt',
    textAlign: 'center',
    color: '#fff',
    fontSize: '36px',
    textTransform: 'uppercase',
    lineHeight: 1.2,
    fontWeight: 800,
    animation: 'fadein 1s',
    '@media (max-width: 520px)': {
      fontSize: 24,
      textAlign: 'center',
	  },
  },
  h1Why: {
    marginTop: 0,
    fontFamily: 'SceneProUltBlkIt',
    textAlign: 'center',
    color: '#fff',
    fontSize: '22px',
    textTransform: 'uppercase',
    lineHeight: 1.2,
    fontWeight: 800,
    animation: 'fadein 1s',
    '@media (max-width: 520px)': {
      fontSize: 20,
      textAlign: 'center',
	  },
  },
  WhyDesc: {
    fontFamily: `SceneProRg`,
    color: '#fff',
    fontSize: '18px',
    lineHeight: 1.8,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    animation: 'fadein 1s',
  },
  desc: {
    fontFamily: 'SceneProRg',
    color: '#808080',
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 500,
    textTransform: 'uppercase',
    textAlign: 'center',
    marginBottom: '1.25rem',
    animation: 'fadein 1s',
    '@media (max-width: 520px)': {
      fontSize: '16px',
      textAlign: 'center',
	  },
  },
  benefitIcon: {
    fontFamily: `SceneProRg`, 
    fontSize: '16px',
    fontWeight: 800,
    color: '#FFF',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto',
    display: 'flex',
    width: '48px',
    height: '48px',
    border: '2px solid #fff',
    borderRadius: '100%',
    marginBottom: '24px',
    '@media (max-width: 520px)': {
      width: '38px',
      height: '38px',
	  },
  },
}));

const Services = () => {
  const { classes } = useStyles();

  return (
    <>
    <Container size="xl" id="#services">
      <div className={classes.wrapper}>
        <Title className={classes.h1}>OUR DETAILING SERVICES</Title>
        <div className={classes.desc}>
          SUPERIOR SERVICE SUPERIOR QUALITY
        </div>

        <SimpleGrid
        cols={3}
        spacing="lg"
        breakpoints={[
          { maxWidth: '62rem', cols: 3, spacing: 'md' },
          { maxWidth: '48rem', cols: 2, spacing: 'sm' },
          { maxWidth: '36rem', cols: 1, spacing: 'sm' },
        ]}
        >
          
          <ServicesCard
          titleProp='Paint Protection Film'
          linkProp='/paint-protection-film'
          imageProp={PPF}
          comingSoonProp={true}
          descriptionProp='Protect and preserve the paint of your vehicle against rock chips, road debris and minor scratches with our professionally installed Paint Protection Film.'
          />
          
          <ServicesCard
          titleProp='Vinyl Wrap'
          linkProp='/vinyl-wrap'
          imageProp={Vinyl}
          comingSoonProp={true}
          descriptionProp={`If you're looking to give your car a new look without spending a lot of money or permanently changing its appearance, vinyl car wrapping is the way to go.`}
          />

          <ServicesCard
          titleProp='Ceramic Coating'
          linkProp='/ceramic-coatings'
          imageProp={CeramicCoating}
          reccomendedProp={true}
          descriptionProp='Provides durable and long-lasting protection to surfaces from light scratches, UV rays, chemicals, and environmental contaminants.'
          />
          
          <ServicesCard
          titleProp='Paint Correction'
          linkProp='/paint-correction'
          imageProp={Tesla}
          popularProp={true}
          descriptionProp='Is your car starting to develop swirls or lacking that original gloss and luster from when you first bought it? Then we have the solution for you.'
          />
          
          <ServicesCard
          titleProp='Auto Detailing'
          linkProp='/detailing'
          imageProp={BMWInterior}
          descriptionProp='Much more than just your ordinary car wash. Come see what the premium side of detailing can do for your vehicle.'
          />
          
          <ServicesCard
          titleProp='Additional Services'
          linkProp='/additional-services'
          imageProp={BMWSeats}
          descriptionProp='Customize your detailing experience with the additional services we offer'
          />

        </SimpleGrid>

      </div>
    </Container>
    
    </>
  )
}

export default Services;