import React, { useRef } from 'react';
import { useNavigate } from "react-router-dom";
import emailjs from '@emailjs/browser';
import 'react-toastify/dist/ReactToastify.css';
import data from '../data/why.json';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Button, SimpleGrid, Select, Title, createStyles } from '@mantine/core';
import {Input, Textarea} from "@nextui-org/react";
import ContactUsHero from '../components/Heroes/ContactUsHero';
import Map from '../components/Map';


const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: '15px',
    paddingBottom: 80,
    position: 'relative',
  },
  h1: {
    marginTop: 0,
    fontFamily: `Outfit, ${theme.fontFamily}`,
    textAlign: 'center',
    color: '#fff',
    fontSize: '3.13rem',
    lineHeight: 1.2,
    fontWeight: 800,
    '@media (max-width: 520px)': {
      fontSize: 28,
      textAlign: 'center',
	  },
  },
  title: {
    marginTop: 0,
    marginBottom: '.5rem',
    fontFamily: `Outfit, ${theme.fontFamily}`,
    textAlign: 'center',
    color: '#fff',
    fontSize: '2.13rem',
    lineHeight: 1.2,
    fontWeight: 800,
    '@media (max-width: 520px)': {
      fontSize: 28,
      textAlign: 'center',
	  },
  },
  desc: {
    fontFamily: `Outfit, ${theme.fontFamily}`,
    color: '#ababab',
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '1.25rem',
  },
  p: {
    fontFamily: `SceneProRg`,
    color: '#ababab',
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 500,
    marginBottom: '1.25rem',
  },
  paper: {
    backgroundColor: '#2f2f2f',
    border: '0px solid',
    borderRadius: 12,
    fontWeight: 600,
    [theme.fn.smallerThan('sm')]: {
    },
  },
  group: {
    padding: 24,
  },
  serviceTitle: {
    color: 'rgba(255, 255, 255, .87)',
    fontSize: 16,
    fontWeight: 600,
  },
  serviceDescription: {
    color: 'rgba(235, 235, 235, .6)',
    paddingTop: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  control: {
    fontFamily: `SceneProRg`,
    fontWeight: 400,
    fontSize: '1rem',
    transition: 'color .25s,border-color .25s,background-color .25s',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
    },
  },
  list: {
    [theme.fn.smallerThan('sm')]: {
      marginBottom: 20,
    },
  },
  listItem: {
    fontFamily: `SceneProRg`,
    display: 'flex',
    padding: '1.5rem 2rem',
    alignItems: 'center',
    gridColumnGap: '0.75rem',
    border: '1px solid rgba(156,175,223,.11)',
    borderRadius: '10.375rem',
    backgroundImage: 'linear-gradient(180deg,rgba(50,60,131,.16),rgba(50,60,131,.16))',
    fontSize: '1.25rem',
    lineHeight: 1,
    color: '#FFF',
    margin: 'auto'
  },
  detailWrap: {
    display: 'flex',
    margin: '3.75rem auto 5rem',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumnGap: '1rem',
    gridRowGap: '1rem',
    [theme.fn.smallerThan('sm')]: {
      marginTop: '2rem',
      marginBottom: '3rem',
      gridColumnGap: '0.7rem',
      gridRowGap: '0.7rem'
    },
  },
  whyUs: {
    display: 'flex',
    padding: '2rem',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gridRowGap: '1.25rem',
    border: '1px solid rgba(202,204,255,.1)',
    borderRadius: '5px',
    // backgroundColor: 'rgb(15,15,15)',
  },
  whyDetailInner: {
    fontFamily: `SceneProRg`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumnGap: '0.75rem',
    fontSize: '1.25rem',
    lineHeight: 1,
    [theme.fn.smallerThan('sm')]: {
      gridColumnGap: '0.5rem',
      fontSize: '16px',
    },
  },
  video: {
    borderRadius: '5px',
    width: '100%',
    height: '350px',
    // boxShadow: '0 15px 50px 0 rgb(23 25 47)',
    [theme.fn.smallerThan('sm')]: {
      height: '250px',
    },
    [theme.fn.smallerThan('md')]: {
      height: '295px',
    },
  },
  form: {
    marginBottom: 0,
    paddingLeft: '2rem',
    borderRadius: '0px',
    // backgroundColor: 'rgb(15,15,15)',
    [theme.fn.smallerThan('md')]: {
      paddingLeft: '0',
    },
  },
  formTitle: {
    marginBottom: '10px',
    color: '#ebebeb',
    fontFamily: `SceneProUltBlkIt`,
    fontSize: '1.44rem',
    fontWeight: 700,
    paddingBottom: '1.25rem',
  },
  whyChooseUs: {
    marginBottom: '0.25rem',
    color: '#ebebeb',
    fontFamily: `SceneProUltBlkIt`,
    fontSize: '1.44rem',
    fontWeight: 700,

  }
}));

const Contact = () => {
  const { classes } = useStyles();
  const form = useRef();
  const navigate = useNavigate();

  const notify = () => toast.success('Thank you, your request has been sent!', {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
    
  });

  const contactError = () => toast.error('There was an error.', {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "dark",
  });

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(`${process.env.REACT_APP_SERVICE_ID_KEY}`, `${process.env.REACT_APP_TEMPLATE_ID_KEY}`, form.current, `${process.env.REACT_APP_PUBLIC_ID_KEY}`)
      .then((result) => {
        navigate('/');
        setTimeout(() => {
          notify();
        }, 1);
      }, (error) => {
        contactError();
      });
  };

  return (
    <>
    <ContactUsHero />
    <Container size="xl">
      <div className={classes.wrapper}>
        <SimpleGrid
        cols={2}
        spacing="xl"
        breakpoints={[
          { maxWidth: 980, cols: 2, spacing: 'md' },
          { maxWidth: 755, cols: 1, spacing: 'sm' },
          { maxWidth: 600, cols: 1, spacing: 'sm' },
        ]}
        >
          <div>
            <iframe 
            className={classes.video} 
            src="https://www.youtube.com/embed/dpqVKHSZFYs" 
            style={{ marginBottom: 25 }} 
            title="YouTube video player" 
            frameborder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowfullscreen>
            </iframe>
            <div className={classes.whyUs}>
            {/* <div className={classes.title}>What happens next?</div> */}
            <Title className={classes.whyChooseUs}>WHY YOU SHOULD CHOOSE US</Title>
            {data.map((why, key) => {
              return (
                <div className={classes.whyDetailInner} key={key}>
                 <svg clip-rule="evenodd" fill="#FFF" width="24" height="24" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m11.998 2.005c5.517 0 9.997 4.48 9.997 9.997 0 5.518-4.48 9.998-9.997 9.998-5.518 0-9.998-4.48-9.998-9.998 0-5.517 4.48-9.997 9.998-9.997zm0 1.5c-4.69 0-8.498 3.807-8.498 8.497s3.808 8.498 8.498 8.498 8.497-3.808 8.497-8.498-3.807-8.497-8.497-8.497zm-5.049 8.886 3.851 3.43c.142.128.321.19.499.19.202 0 .405-.081.552-.242l5.953-6.509c.131-.143.196-.323.196-.502 0-.41-.331-.747-.748-.747-.204 0-.405.082-.554.243l-5.453 5.962-3.298-2.938c-.144-.127-.321-.19-.499-.19-.415 0-.748.335-.748.746 0 .205.084.409.249.557z" fill-rule="nonzero"/></svg>
                <div>{why.title}</div>
              </div>
            )})}
            </div>
          </div>
          <div className={classes.form}>
            <Title className={classes.formTitle}>COMPLETE THE FORM BELOW</Title>
            <form ref={form} onSubmit={sendEmail}>
            <div className="w-full grid grid-cols-1 gap-4">
            <Input

      type="name"
      label="First and Last Name"
      variant="bordered"
      className="max"
    />
              {/* <TextInput
              required
              label="First and Last Name"
              placeholder="Enter your first and last name"
              styles={(theme) => ({ 
                input: { 
                  marginBottom: 10, 
                  border: '1px solid rgba(202,204,255,.1)', 
                  borderRadius: '0px',
                  backgroundColor: 'rgba(36,53,91,.05)', 
                  color: '#dedede', 
                  fontFamily: `SceneProRg`,
                  '&:hover': {
                    backgroundColor: '#0c101b',
                  },
                },
                placeholder: { color: 'rgba(235, 235, 235, .6)' },
                inputFocus: { backgroundColor: '#42b883', border: '1px solid #e80200' },
                label: { 
                  marginBottom: 10,
                  color: '#FFF',
                  fontFamily: `SceneProRg`
                },
              })}
              size="lg"
              name="from_name"
              type="text"
              pattern="^(\w\w+)\s(\w+)$"
              title="Please enter your first and last name"
              /> */}
                          <Input

type="email"
label="Email"
variant="bordered"
className="max"
/>
                            {/* <TextInput
              required
              label="Email"
              placeholder="Enter your email"
              styles={(theme) => ({
                input: { 
                  marginBottom: 10, 
                  border: '1px solid rgba(202,204,255,.1)',
                  backgroundColor: 'rgba(36,53,91,.05)', 
                  color: '#dedede', 
                  fontFamily: `SceneProRg`, 
                  borderRadius: '0px',
                  '&:hover': {
                    backgroundColor: '#0c101b',
                  },
                },
                placeholder: { color: 'rgba(235, 235, 235, .6)', },
                inputFocus: { backgroundColor: '#42b883', border: '1px solid #3444da' },
                label: { 
                  marginBottom: 10,
                  color: '#FFF',
                  fontFamily: `SceneProRg`
                },
              })}
              size="lg"
              name="email"
              type="email"
              title="Please enter a valid email address"
              /> */}
                          <Input

type="tel"
label="Phone Number"
variant="bordered"
className="max"
/>
              {/* <TextInput
              required
              label="Phone Number"
              styles={(theme) => ({
                input: { 
                  marginBottom: 10, 
                  border: '1px solid rgba(202,204,255,.1)', 
                  backgroundColor: 'rgba(36,53,91,.05)', 
                  color: '#dedede', 
                  fontFamily: `SceneProRg`, 
                  borderRadius: '0px',
                  '&:hover': {
                    backgroundColor: '#0c101b',
                  },
                },
                placeholder: { color: 'rgba(235, 235, 235, .6)' },
                inputFocus: { backgroundColor: '#42b883', border: '1px solid #3444da' },
                label: {
                  marginBottom: 10,
                  color: '#FFF',
                  fontFamily: `SceneProRg`
                },
              })}
              size="lg"
              name="number"
              type="number"
              placeholder="888 888 8888" 
              maxlength="10"
              title="Please enter your 10 digit phone number"
              /> */}

<Textarea
      label="Questions/Comments"
      variant="bordered"
      labelPlacement="inside"
      value="Please give us a brief description of your questions/comments/concerns."
      className="max"
    />
              
              {/* <Textarea
              label="Questions/Comments"
              placeholder=""
              styles={(theme) => ({
                input: { 
                  marginBottom: 10, 
                  border: '1px solid rgba(202,204,255,.1)',
                  backgroundColor: 'rgba(36,53,91,.05)', 
                  color: '#dedede', 
                  fontFamily: `SceneProRg`,
                  borderRadius: '0px',
                  '&:hover': {
                    backgroundColor: '#0c101b',
                  },
                },
                placeholder: { color: '#585959' },
                inputFocus: { backgroundColor: '#42b883', border: '1px solid #3444da' },
                label: {
                  marginBottom: 10,
                  fontFamily: `SceneProRg`,
                  color: '#FFF',
                },
              })}
              size="lg"
              minRows={4}
              name="description"
              /> */}
              </div>
              <div style={{ margin: 'auto', display: 'block', float: 'right' }}>
                <Button 
                type="submit"
                size="lg" 
                className={classes.control}
                styles={(theme) => ({
                  root: {
                    flex: 1,
                    backgroundColor: '#e80200',
                    border: '2px solid #e80200',
                    fontFamily: 'SceneProRg',
                    textTransform: 'uppercase',
                    fontWeight: 800,
                    letterSpacing: '1px',
                    width: '100%',
                    borderRadius: '0px',
                    '&:hover': {
                      backgroundColor: '#050505',
                      boxShadow: '0 0 4px 0 #e80200',
                    },
                  },
                  leftIcon: {
                    marginRight: 15,
                  },
                })}
                style={{ marginTop: 20, padding: '0px 20px' }}
                >
                  Send Message
                </Button>
              </div>
            </form>
          </div>
        </SimpleGrid>
      </div>
    </Container>
    <Map />
    <ToastContainer
    position="bottom-left"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    theme="dark"
    />
    </>
  )
}

export default Contact;