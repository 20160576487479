import React from 'react';
import { Container, Card, Title, Text, Group, Badge, Image, Button, Center, SimpleGrid, createStyles, getStylesRef, rem } from '@mantine/core';
import { Link } from 'react-router-dom';
import InteriorHero from '../components/Heroes/InteriorHero';
import AddOnServices from '../components/Detailing/AddOnServices';
import AddOnHero from '../components/Heroes/AddOnHero';
import WhyAddOns from '../components/BenefitsAndImportance/WhyAddOns';
import VideoIntroduction from '../components/BenefitsAndImportance/VideoIntroduction';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: '15px',
    paddingBottom: '15px',
    position: 'relative',
  },
  h1: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    fontFamily: 'SceneProUltBlkIt',
    textAlign: 'center',
    color: '#fff',
    fontSize: '36px',
    textTransform: 'uppercase',
    lineHeight: 1.2,
    fontWeight: 800,
    animation: 'fadein 1s',
    '@media (max-width: 520px)': {
      fontSize: 24,
      textAlign: 'center',
	  },
  },
  paper: {
    backgroundColor: '#2f2f2f',
    border: '0px solid',
    borderRadius: 12,
    fontWeight: 600,
    [theme.fn.smallerThan('sm')]: {
    },
  },
  group: {
    padding: 24,
  },
  serviceTitle: {
    color: 'rgba(255, 255, 255, .87)',
    fontSize: 16,
    fontWeight: 600,
  },
  serviceDescription: {
    color: 'rgba(235, 235, 235, .6)',
    paddingTop: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  list: {
    [theme.fn.smallerThan('sm')]: {
      marginBottom: 20,
    },
  },
  listItem: {
    color: '#FFF',
    fontFamily: `Outfit, ${theme.fontFamily}`,
    fontSize: '18px',
    margin: 'auto'
  },
  smallHeading: {
    fontFamily: `Evogria, ${theme.fontFamily}`,
    color: '#ebebeb',
    fontSize: '2rem',
    lineHeight: '1.28125',
    textAlign: 'center',
    marginBottom: '1.25rem',
  },
  desc: {
    fontFamily: `Evogria, ${theme.fontFamily}`,
    color: '#ababab',
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '1.25rem',
    animation: 'fadein 1s',
  },
  control: {
		paddingLeft: 50,
		paddingRight: 50,
		fontFamily: `Outfit, ${theme.fontFamily}`,
		fontSize: '1.125rem',
		fontWeight: 600,
		transition: 'color .25s,border-color .25s,background-color .25s',
		justifyContent: 'center',
		alignItems: 'center',
	
		[theme.fn.smallerThan('md')]: {
		  width: '100%',
		},
  },
  card: {
    backgroundColor: 'rgb(15,15,15)',
    color: '#FFF',
    fontFamily: `Outfit, ${theme.fontFamily}`,
    border: '1px solid rgba(202,204,255,.1)',
  },

}));

const AddOnServicesPage = () => {
  const { classes, theme } = useStyles();

  return (
    <>
    <AddOnHero />
    <VideoIntroduction
          titleProp='GET YOUR CAR LOOKING LIKE NEW WITHOUT ANY BODY WORK'
          descriptionProp={`Ceramic Coating your paint is the next generation in Paint Protection. It has been designed to form a semi-permanent crystal layer that will make conventional car waxes and sealants obsolete, due their high strength as well an extraordinary levels of gloss. All created by superior cross linking abilities made possible by condensed nanoparticles, leaving you with the aesthetic and performance you've always wanted for you car!`}
          videoProp='9HhIhYyoPic'
          buttonText='VIew Our Packages'
          buttonLink='https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN'
          />
    <WhyAddOns />
    <Container size="xl">
      <div className={classes.wrapper}>
        <Title className={classes.h1} mt="md">OUR ADDITIONAL SERVICES</Title>
        <AddOnServices />
      </div>
    </Container>
    </>
  )
}

export default AddOnServicesPage;