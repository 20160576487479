import React from 'react';
import { Container, Card, Title, Text, Group, Badge, Image, Button, Center, SimpleGrid, createStyles, getStylesRef, rem } from '@mantine/core';
import { Link } from 'react-router-dom';
import DetailingServices from '../components/Detailing/DetailingServices';
import DetailingFAQ from '../components/Detailing/DetailingFAQ';
import { Helmet } from 'react-helmet';
import HeadlightRestorationHero from '../components/Heroes/HeadlightRestorationHero';
import ImportanceHeadlights from '../components/BenefitsAndImportance/ImportanceHeadlights';
import WhyUsHeadlights from '../components/BenefitsAndImportance/WhyUsHeadlights';

const useStyles = createStyles((theme) => ({
  wrapper: {
    paddingTop: '4rem',
    paddingBottom: '4rem',
    position: 'relative',
  },
  h1: {
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    fontFamily: 'SceneProUltBlkIt',
    textAlign: 'center',
    color: '#fff',
    fontSize: '36px',
    textTransform: 'uppercase',
    lineHeight: 1.2,
    fontWeight: 800,
    animation: 'fadein 1s',
    '@media (max-width: 520px)': {
      fontSize: 24,
      textAlign: 'center',
	  },
  },
  paper: {
    backgroundColor: '#2f2f2f',
    border: '0px solid',
    borderRadius: 12,
    fontWeight: 600,
    [theme.fn.smallerThan('sm')]: {
    },
  },
  group: {
    padding: 24,
  },
  serviceTitle: {
    color: 'rgba(255, 255, 255, .87)',
    fontSize: 16,
    fontWeight: 600,
  },
  serviceDescription: {
    color: 'rgba(235, 235, 235, .6)',
    paddingTop: 8,
    fontSize: 14,
    fontWeight: 500,
  },
  list: {
    [theme.fn.smallerThan('sm')]: {
      marginBottom: 20,
    },
  },
  listItem: {
    color: '#FFF',
    fontFamily: `Outfit, ${theme.fontFamily}`,
    fontSize: '18px',
    margin: 'auto'
  },
  smallHeading: {
    fontFamily: `Evogria, ${theme.fontFamily}`,
    color: '#ebebeb',
    fontSize: '2rem',
    lineHeight: '1.28125',
    textAlign: 'center',
    marginBottom: '1.25rem',
  },
  desc: {
    fontFamily: `Evogria, ${theme.fontFamily}`,
    color: '#ababab',
    fontSize: '1.25rem',
    lineHeight: 1.6,
    fontWeight: 500,
    textAlign: 'center',
    marginBottom: '1.25rem',
    animation: 'fadein 1s',
  },
  control: {
		paddingLeft: 50,
		paddingRight: 50,
		fontFamily: `Outfit, ${theme.fontFamily}`,
		fontSize: '1.125rem',
		fontWeight: 600,
		transition: 'color .25s,border-color .25s,background-color .25s',
		justifyContent: 'center',
		alignItems: 'center',
	
		[theme.fn.smallerThan('md')]: {
		  width: '100%',
		},
  },
  card: {
    backgroundColor: 'rgb(15,15,15)',
    color: '#FFF',
    fontFamily: `Outfit, ${theme.fontFamily}`,
    border: '1px solid rgba(202,204,255,.1)',
  },
  WhyDesc: {
    fontFamily: `SceneProRg`,
    color: '#fff',
    fontSize: '18px',
    lineHeight: 1.8,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: '1.25rem',
    marginBottom: '1.25rem',
    animation: 'fadein 1s',
  },
}));

const HeadlightRestoration = () => {
  const { classes, theme } = useStyles();

  return (
    <>
    <Helmet>
      <title>Headlight Restoration In Elizabethtown, KY | Element Seventy Eight Detailing</title>
      <meta name='title' content='Headlight Restoration In Elizabethtown, KY | Element Seventy Eight Detailing' />
      <meta name='description' content='Elizabethtown, KY Headlight Restoration' />
      <meta name='keywords' content='Elizabethtown ky paint correction, Elizabethtown mobile car detailing, Elizabethtown undercarriage detailing, Elizabethtown headlight restoration, Elizabethtown mobile car wash, Elizabethtown ceramic coating, Elizabethtown paint correction, Elizabethtown mobile detail' />
      <meta property="og:title" content='Headlight Restoration In Elizabethtown, KY | Element Seventy Eight Detailing' />
      <meta property="og:description" content='Elizabethtown, KY Headlight Restoration' />
      <meta property="og:image" content='%PUBLIC_URL%/preview.png' />
    </Helmet>
    <HeadlightRestorationHero />
    <ImportanceHeadlights />
    <Container size="xl">
    {/* <ExteriorTable /> */}

      <div className={classes.wrapper}>
        <Title className={classes.h1}>What Is Headlight Restoration?</Title>
        <div>
        <p className={classes.WhyDesc}>Headlight restoration is the process of restoring the clarity and brightness of your car's headlights. Over time, headlights can become foggy, yellowed, and scratched, making them less effective at night and in poor weather conditions. Headlight restoration near me can remove these imperfections and restore your headlights to their original condition.</p>
      </div>
      </div>
    </Container>
    <WhyUsHeadlights />
    </>
  )
}

export default HeadlightRestoration;