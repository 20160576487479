import React from 'react'
import PackageCard from '../PackageCard';
import { SimpleGrid } from '@mantine/core';

const PaintCorrectionComponent = () => {
  const stageOne = [
    'Includes our Gold package', 
    'Paint is analyzed using a paint depth gauge to determine thickness', 
    '1 Step Polishing (removes light swirls/scratches)', 
    'Trim, decals and emblems are taped off for protection',
    'Paint sealant applied for long-lasting protection',
  ]

  const stageTwo = [
    'This stage is recommended for vehicles that are older or have moderate scratches and/or swirl marks',
    'Includes everything in Step 1 Correction and the additional services below', 
    'Additional round of compounding, which removes more serious swirls/scratches', 
  ]
  
  const stageThree = [
    '<strong>Wet Sanding to level Paint Texture (Orange Peel) or Paint Correction for Severe Defect Removal</strong>'
  ]

  return (
    <div>
      <SimpleGrid
      cols={2}
      spacing="lg"
      breakpoints={[
        { maxWidth: '62rem', cols: 2, spacing: 'md' },
        { maxWidth: '48rem', cols: 1, spacing: 'sm' },
        { maxWidth: '36rem', cols: 1, spacing: 'sm' },
      ]}
      >
        <PackageCard
        packageTitle="One Stage Correction"
        packageDuration=""
        included={stageOne}
        price="$500 - $600"
        bookingLink="https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN"
        />
        <PackageCard
        packageTitle="Two Stage Correction"
        packageDuration=""
        included={stageTwo}
        price="$650 - $800"
        bookingLink="https://app.urable.com/virtual-shop/nm5qG9H2g5ahH4Xkm9TN"
        />
      </SimpleGrid>
    </div>
  )
}

export default PaintCorrectionComponent;